import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import get from 'lodash/get'
import Layout from '../components/layout'

import NavigationList from "../components/navigation-list";

class CreationTypeTemplate extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const creations = get(this, 'props.data.allContentfulWerk.edges')
    const type = creations[0].node.type;
    return (
      <Layout location={this.props.location} showNavigation={true} path={[
        {url: '/werk', text: 'Werke'},
        {text: type}
        ]}>
        <Helmet title={siteTitle} />
        <NavigationList
          links={_.orderBy(creations.map((creation => creation.node)), 'date', 'desc').map((creation => {
            const path = `/werk/${creation.type}/${creation.slug}`;
            return {path: path.toLowerCase(),
              text: creation.titleShort}
          }))}>
        </NavigationList>
      </Layout>
    )
  }
}

export default CreationTypeTemplate

export const pageQuery = graphql`
  query WerkIndexQuery ($type: String!) {
    site {
      siteMetadata {
        title
      }
    }
     allContentfulWerk(filter: {type: {eq: $type}}) {
      edges {
        node {
          titleShort
          title
          type
          slug
          date
        }
      }
    }
  }
`
