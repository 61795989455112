import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation-list.module.css'

export default (props) => (
  <div className={styles.navigationList}>
    { props.title &&
      <h1>{props.title}</h1>
    }
    <nav role="navigation">
      <ul className={styles.navigation}>
        {props.links && props.links.map(link => (
          <li className={styles.navigationItem} key={link.text}>
            <Link to={link.path}>{link.text}</Link>
          </li>
        ))}
      </ul>
    </nav>
  </div>
)
